<template>
<v-card flat class="mt-5">
    <v-card-text>
        <div id="app">

            <v-snackbar v-model="snackbartest.visible" auto-height :color="snackbartest.color" :multi-line="snackbartest.mode === 'multi-line'" :timeout="snackbartest.timeout" :top="snackbartest.position === 'top'">
                <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbartest.icon }}</v-icon>
                    <v-layout column>
                        <div>
                            <strong>{{ snackbartest.title }}</strong>
                        </div>
                        <div>{{ snackbartest.text }}</div>
                    </v-layout>
                </v-layout>
                <v-btn v-if="snackbartest.timeout === 0" icon @click="snackbartest.visible = false">
                    <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
                <v-row>
                    <v-col cols="4">
                            <v-select :items="listpays2" v-model="selectpays2" @change="PaysSearch2()" dense item-value="id" outlined label="Liste des pays">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.code_pays }} - {{ data.item.libelle }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.code_pays }} - {{ data.item.libelle }}
                                                </template>
                                            </v-select>
                                          
                    </v-col>
                     <v-col cols="6">
                            <v-select :items="listcible2" v-model="selectcible2" @change="CibleSearch2()" dense item-value="id"  outlined label="Liste des Cibles">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.description }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.description }}
                                                </template>
                                            </v-select>
                                          
                    </v-col>
                </v-row>
            <v-data-table :headers="headers" :items="list" sort-by="id" class="elevation-1">
                <template v-slot:[`item.image`]="{ item }">
                    <div class="p-2">
                        <v-img :src="lienimage+item.image" :alt="item.libelle" height="50px" width="50"></v-img>
                    </div>
                </template>

                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>Liste Indicateurs extrats ODD par pays </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="650px">

                            <v-snackbar v-model="snackbar" :multi-line="multiLine">
                                {{ text }}

                                <template v-slot:action="{ attrs }">
                                    <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                                        Fermer
                                    </v-btn>
                                </template>
                            </v-snackbar>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="nouveau()">
                                    Nouveau
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <v-select :items="listpays" v-model="selectpays" dense item-value="id" :rules="[v => !!v || 'Veuillez selectionnez le pays']" required outlined label="Liste des pays">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.code_pays }} - {{ data.item.libelle }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.code_pays }} - {{ data.item.libelle }}
                                                </template>
                                            </v-select>
                                             <v-select :items="listcible" v-model="selectcible" dense item-value="id" @change="CibleSearch()" :rules="[v => !!v || 'Veuillez selectionnez les cibles des odds']" required outlined label="Liste des cibles">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.description }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.description }}
                                                </template>
                                            </v-select>
                                             <v-select :items="listmethode" v-model="selectemethode" dense item-value="id" @change="selectypemethode()" outlined label="Choix de la methode">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.libellemethode }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.libellemethode }}
                                                </template>
                                            </v-select>
                                             <v-select :items="listindicateur" v-model="selecteindicateur" dense item-value="id" :disabled="para_indicateur" @change="IndicateurSearch_only()" outlined label="Liste des indicateurs">
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.libelle }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ data.item.code }} - {{ data.item.libelle }}
                                                </template>
                                            </v-select>
                                         <v-text-field v-model="code" label="Indicateur CODE" dense outlined :disabled="para_code"></v-text-field>
                                            <v-textarea v-model="libelle" label="Reformulation de l'indicateur au niveau du pays" dense outlined required></v-textarea>
                                             <v-textarea v-model="libelle_definition" label="définition de l'indicateur" dense outlined required></v-textarea>

                                        </v-form>

                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="dialog=false">
                                        Fermer
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save()">
                                        Enregistrer
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Confirmer</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
               
                
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                </template>
                <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize()">
                        Actualiser la liste
                    </v-btn>
                </template>
            </v-data-table>

        </div>

    </v-card-text>
</v-card>
</template>

<script>
import api from './../../../serviceApi/apiService'

export default {
    props: {
        informationData: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        multiLine: true,
        snackbartest: false,
        textmessage: '',
        snackbar: false,
        snackbars: false,
        text: `message`,
        textsnackbar: 'messages',
        dialog: false,
        dialogDelete: false,
        headers: [{
                text: 'ID',
                value: 'id',
                divider: true,
                width: '10px'
            },
            {
                text: 'Code Indicateur',
                value: 'code',
                divider: true,
                width: '10px'
            },
            {
                text: 'Indicatleur racine',
                align: 'start',
                sortable: false,
                value: 'indicateur_racine.libelle',
                divider: true,
                width: '200px'
            },
            {
                text: 'Reformulation Indicateur',
                align: 'start',
                sortable: false,
                value: 'libelle',
                divider: true,
                width: '250px'
            },
            {
                text: 'Definition Indicatleur',
                align: 'start',
                sortable: false,
                value: 'definition',
                divider: true,
                width: '250px'
            },

            {
                text: 'cible',
                align: 'start',
                sortable: false,
                value: 'cibles.description',
                divider: true,
                width: '200px'
            },
             {
                text: 'Libelle Pays',
                align: 'start',
                sortable: false,
                value: 'pays.libelle',
                divider: true,
                width: '100px'
            },
           
            {
                text: 'Actions',
                value: 'actions',
                sortable: false,
                divider: true,
                width: '15px'
            },
        ],
        formTitle: 'Formulaire',
        desserts: [],
        list: [],
        listcible: [],
        listcible2: [],
        listpays: [],
        listmethode: [{id: 1, libellemethode: 'Choix Indicateur au niveau de la racine'}, {id: 2, libellemethode: 'Saisie Indicateur extrat'}],
        listindicateur:[],
        listindicateur2:[],
        listindicateur3:[],
        valid: true,
      
        id_cibles: '0',
        selectcible: '0',
        selectcible2:'0',
        selectpays: '0',
       // selectindicateur: '0',
        selecteindicateur: '0',
        selectemethode:'0',
        
        code: '',
        libelle: '',
        libelle_definition: '',
       
       editedIndex: -1,
        textetat: false,
        messages: '',
        para_code:true,
        para_indicateur:false,

    }),

    methods: {
        //=====Affichages  liste des odds==========
        readAll: async function () {
            const data = await api.readAll('indicateur-odd-extrat/all');
            this.list = data.list;
            this.list2 = data.list;
        },

        readcibleAll: async function () {
            const data = await api.readAll('cibles/all');
            this.listcible = data.list;
            this.listcible2 = data.list;
           
        },

        readIndicateurAll: async function () {
            const data = await api.readAll('indicateur-odd/all');
            this.listindicateur3 = data.list;
          
        },

          readAllpays: async function () {
            const data = await api.readAll('parametre/all-pays');
            this.listpays = data.list;
            this.listpays2 = data.list;
            //console.log("ooookkk");
        },

        deleteData: async function () {
            let fd = new FormData();
            fd.append('id', this.id_odds);
            const res = await api.createUpdatedata('indicateur-odd/delete', fd);
            if (res.status == 200) {

            }
            // this.messagesligne(res);
        },
        getdefiition(item){
          if(item.definition==null){
            return '';
          }
          return item.definition;  
        },

        createUpdatedata: async function () {

            let fd = new FormData();
            fd.append('id_cibles', this.selectcible);
            fd.append('code', this.code);
            fd.append('libelle', this.libelle);
            fd.append('definition', this.libelle_definition);
            fd.append('id_indicateurs', this.selecteindicateur);
            fd.append('id_pays', this.selectpays);

            if (this.textetat) {

                const res = await api.createUpdatedata('indicateur-odd-extrat/update/' + this.id_odds, fd);
                if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                    this.dialog = false;
                }

            } else {
                const res = await api.createUpdatedata('indicateur-odd-extrat/add', fd);
                if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };

                }

            }
            this.clear();
            this.readAll();
        },
        nouveau() {
           this.clear();

        },

       
        clear() {

            this.code = "";
            this.libelle = "";
            this.libelle_definition = "";
            this.selectcible = '0';
            this.selectpays = '0';
            this.selecteindicateur = '0';
            this.selectemethode = '0';
        
            this.textetat = false;
        },
        deleteItem(item) {
            this.id_odds = item.id;
            this.dialogDelete = true;

        },
        editItem(item) {
            this.id_odds = item.id;
            this.libelle = item.libelle;
            this.code = item.code;
            this.textetat = true;
            this.dialog = true;
        },

        deleteItemConfirm() {
            this.deleteData();
            this.readAll();
            this.dialogDelete = false
        },

        closeDelete() {
            this.dialogDelete = false
        },
        selectypemethode() {
            if (this.selectemethode == '1') {
                this.code = "";
                this.para_code=true;
                this.para_indicateur=false;
                this.selecteindicateur='0';
            } else {
                this.code="";
                this.selecteindicateur='0';
                this.para_code=false;
                this.para_indicateur=true;
            }
        },

        save() {
            if (this.libelle == '' || this.code == '') {

                this.textmessage='Veuillez les remplirs les champs '
                    this.snackbartest = {
                        color: "red",
                        icon: "mdi-alert",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                return;
            }

            this.createUpdatedata();
            this.readAll();
            if (this.textetat) {
                this.dialog = false;
            }
        },
        initialize(){
            this.readAll();
        },
          CibleSearch() {
            //selec
            this.code = "";
            this.libelle_definition = "";
            this.libelle = "";
            
            this.listindicateur = [];
            this.listindicateur = this.listindicateur3.filter(this.filtrerParID_Indicateur);
        },
          CibleSearch2() {
           
            this.list = [];
            this.list = this.list2.filter(this.filtrerParID_table2);
        },
         PaysSearch2() {
           
            this.list = [];
            this.list = this.list2.filter(this.filtrerParID_table3);
        },
         filtrerParID_table3: function (obj) {
            if (obj.id_pays == this.selectpays2) {
                return true;
            } else {
                return false;
            }
        },
         filtrerParID_table2: function (obj) {
            if (obj.id_cibles == this.selectcible2 && obj.id_pays == this.selectpays2) {
                return true;
            } else {
                return false;
            }
        },
          filtrerParID_Indicateur: function (obj) {
            if (obj.id_cibles == this.selectcible) {
                return true;
            } else {
                return false;
            }
        },

        filtrerParID_Indicateur_onlys: function (obj) {
            if (obj.id == this.selecteindicateur) {
                return true;
            } else {
                return false;
            }
        },

            IndicateurSearch_only() {
           // this.listindicateur = [];
            this.code = this.listindicateur3.filter(this.filtrerParID_Indicateur_onlys)[0].code;
        },


    },
    mounted() {
         this.readAllpays();
        this.readAll();
          this.readIndicateurAll();
        this.readcibleAll();
    },

  

}
</script>
